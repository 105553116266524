let body = document.querySelector('body');

window.addEventListener("DOMContentLoaded", () => {
    body.classList.remove('no-js');
});

// media query event handler
if (matchMedia) {
    var mq = window.matchMedia("(max-width: 768px)");
    mq.addListener(WidthChange);
    WidthChange(mq);
}

// media query change
function WidthChange(mq) {
    if (mq.matches) {

    } else {

    }
};
/* End */

/* Add Browser Class to HTML */
var browser_name = '';
isIE = /*@cc_on!@*/false || !!document.documentMode;
isEdge = !isIE && !!window.StyleMedia;
if (navigator.userAgent.indexOf("Chrome") != -1 && !isEdge) {
    browser_name = 'chrome';
} else if (navigator.userAgent.indexOf("Safari") != -1 && !isEdge) {
    browser_name = 'safari';
} else if (navigator.userAgent.indexOf("Firefox") != -1) {
    browser_name = 'firefox';
} else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) //IF IE > 10
{
    browser_name = 'ie';
} else if (isEdge) {
    browser_name = 'edge';
} else {
    browser_name = 'other-browser';
}

let html = document.querySelector('html');

html.classList.add(browser_name);
/* End */


const navbar = document.querySelector('.navbar')

const onScroll = () => {
    const scroll = document.documentElement.scrollTop
    if (scroll > 0) {
        navbar.classList.add("fixed");
    } else {
        navbar.classList.remove("fixed")
    }
}

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
    });
});

window.addEventListener('scroll', onScroll)

function isElementInViewport(element) {
    const rect = element.getBoundingClientRect();

    const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
    const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

    const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
    const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

    return (vertInView && horInView);
}


let elements = document.querySelectorAll(".animation-on-view");

function callbackFunc() {
    for (let i = 0; i < elements.length; i++) {
        if (isElementInViewport(elements[i])) {
            elements[i].classList.add("in-view");
        }

        /* else {
             elements[i].classList.remove("in-view");
         }*/
    }
}

window.addEventListener("load", callbackFunc);
window.addEventListener("scroll", callbackFunc);
/* End */
